<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                          {{ $t('external_sidebar.transport_schedule') }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('globalTrans.fiscal_year') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ getFiscalYear(formData.fiscal_year_id) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.circular_memo_no : formData.circular_memo_no }}</td>
                      </tr>
                      <!-- <tr v-if="$store.state.Auth.activeRoleId === 1">
                        <th style="width: 20%"  class="text-left">{{ $t('globalTrans.organization') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.org_name_bn : formData.org_name }}</td>
                      </tr>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                      </tr>
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      </tr> -->
                      <tr>
                        <th style="width: 20%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ getTrainingTitle(formData.training_title_id) }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-overlay>
                <!-- <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.transport_schedule')}} {{$t('globalTrans.details')}}</legend>
                      <table class="table" style="width:100%;padding: 5px">
                          <tr style="color:rgb(54, 146, 74);">
                            <th style="width:15%">{{$t('globalTrans.date')}}</th>
                            <th style="width:15%">{{$t('globalTrans.time')}}</th>
                            <th style="width:20%">{{$t('elearning_tpm.driver_name')}}</th>
                            <th style="width:20%">{{$t('elearning_tpm.driver_contact_number')}}</th>
                          </tr>
                        <tr v-for="(item, index) in formData.details" :key="index">
                        <td>
                          {{ item.training_date | dateFormat }}
                        </td>
                        <td>
                          {{ item.start_time | time12FormateTraining }}
                        </td>
                        <td>
                          {{ getDriver(item.driver_id) }}
                        </td>
                        <td>
                          {{ item.driver_contact | mobileNumber }}
                        </td>
                    </tr>
                      </table>
                    </fieldset>
                  </div>
                </b-row> -->
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.arrival')}}</legend>
                      <table class="table" style="width:100%;padding: 5px">
  <tr style="color:rgb(54, 146, 74);">
    <th style="width:15%">{{$t('globalTrans.date')}}</th>
    <th style="width:15%">{{$t('globalTrans.time')}}</th>
    <th style="width:20%">{{$t('elearning_tpm.driver_name')}}</th>
    <th style="width:20%">{{$t('elearning_tpm.driver_contact_number')}}</th>
    <th style="width:22%">{{ $t('elearning_tpm.location') }}</th>
  </tr>
  <tr v-for="(item, index) in Object.values(formData.details || {}).filter(type => type.pick_up_type == 1)" :key="index">
    <td>
      {{ item.training_date | dateFormat }}
    </td>
    <td>
      {{ item.start_time | time12FormateTraining }}
    </td>
    <td>
      {{ getDriver(item.driver_id) }}
    </td>
    <td>
      {{ item.driver_contact | mobileNumber }}
    </td>
    <td>
      {{ (currentLocale === 'bn') ? item.location_bn : item.location_en }}
    </td>
  </tr>
  <tr v-if="Object.values(formData.details || {}).filter(type => type.pick_up_type == 1).length <= 0">
    <td colspan="12" class="text-center">
      <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
    </td>
  </tr>
</table>
                    </fieldset>
                  </div>
                </b-row>
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.departure')}}</legend>
                      <table class="table" style="width:100%;padding: 5px">
  <tr style="color:rgb(54, 146, 74);">
    <th style="width:15%">{{$t('globalTrans.date')}}</th>
    <th style="width:15%">{{$t('globalTrans.time')}}</th>
    <th style="width:20%">{{$t('elearning_tpm.driver_name')}}</th>
    <th style="width:20%">{{$t('elearning_tpm.driver_contact_number')}}</th>
    <th style="width:22%">{{ $t('elearning_tpm.location') }}</th>
  </tr>
  <tr v-for="(item, index) in Object.values(formData.details || {}).filter(type => type.pick_up_type == 2)" :key="index">
    <td>
      {{ item.training_date | dateFormat }}
    </td>
    <td>
      {{ item.start_time | time12FormateTraining }}
    </td>
    <td>
      {{ getDriver(item.driver_id) }}
    </td>
    <td>
      {{ item.driver_contact | mobileNumber }}
    </td>
    <td>
      {{ (currentLocale === 'bn') ? item.location_bn : item.location_en }}
    </td>
  </tr>
  <tr v-if="Object.values(formData.details || {}).filter(type => type.pick_up_type == 2).length <= 0">
    <td colspan="12" class="text-center">
      <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
    </td>
  </tr>
</table>
                    </fieldset>
                  </div>
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { trainingTitleModule } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
  props: ['id'],
  // data () {
  //     return {
  //       loader: false,
  //       formData: {}
  //     }
  //   },
  created () {
    if (this.id) {
      const tmp = this.getData(this.id)
      this.formData = tmp
      this.getAssignedTrainerList()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      courses: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainerListData: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getAssignedTrainerList () {
        if (this.formData.circular_memo_no) {
            this.load = true
            const serchData = {
                circular_memo_no: this.formData.circular_memo_no,
                batch_no: this.formData.batch_no
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/driver-assign/assign-trainer-list', serchData)
            if (result.success) {
                this.trainerListData = result.data
                this.load = false
            } else {
                this.trainerListData = []
                this.load = false
            }
        }
    },
    getTrainer (id) {
      const trainingType = this.trainerListData.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingTypeName (id) {
      const trainingType = this.$store.state.ExternalUserTraining.traineeTranerObj.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingTitleName (id) {
      const trainingType = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingCatName (id) {
      const trainingType = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getFiscalYear (id) {
      const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === id)
      if (this.currentLocale === 'bn') {
        return fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
      } else {
        return fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
      }
    },
    getTrainingTitle (id) {
      const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === id)
      if (this.currentLocale === 'bn') {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_bn : ''
      } else {
        return trainingTitleObj !== undefined ? trainingTitleObj.text_en : ''
      }
    },
    getDriver (id) {
      const trainingType = this.$store.state.ExternalUserTraining.traineeTranerObj.driverList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    async getCircularMemoNo (trainingTitleId) {
      if (trainingTitleId) {
        this.load = true
        const searchData = {
          training_title_id: this.formData.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingTitleModule, searchData)
        if (!result.success) {
          this.courses = []
        } else {
            this.courses = result.data
        }
        this.load = false
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_iabm.lesson_assign_circular_wise')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    },
    async getData (id) {
      this.load = true
      const resultData = await RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/driver-assign/show' + '/' + id)
      const tmpData = resultData.data
      this.formData = tmpData
      this.formData.details = resultData.viewData
      this.load = false
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
